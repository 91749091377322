




































































import { Component, Vue, Prop } from 'vue-property-decorator';
import IAuthenticationService, { AuthenticationInfo, AuthenticationConfirmation } from '@/data/AuthenticationService.Types';

@Component
export default class Login extends Vue {
    public Token: string = '';
    public OTP: string = '';
    public CSRF: string = '';
    public JSESSION: string = '';
    public AuthType: string = '';
    public ShowOTP: boolean = false;
    public ShowTokenSubmit: boolean = true;
    public ShowTokenError: boolean = false;
    public ShowOTPError: boolean = false;

    public get CentralServerURL(): string {
        return this.CentralServiceAPI;
    }

    @Prop({
        required: true
    })
    public AuthenticationService!: IAuthenticationService;

    @Prop({
        required: true
    })
    public CentralServiceAPI!: string;

    @Prop({
        required: false
    })
    public Inactive!: boolean;

    public mounted(): void {
        // const token = localStorage.getItem('user_token');
        const token = this.$route.query.token as string;
        if (token) {
            this.Token = token;
        }
    }

    public Login(event: Event): void {
        this.AuthenticationService.Logout()
            .then(() => {
                this.AuthenticationService.MultiFactorAuthenticationInformation(this.Token)
                    .then(async (info: AuthenticationInfo) => {
                        if (!info.success) {
                            this.ShowTokenError = true;
                            return;
                        }
                        this.ShowTokenError = false;
                        // localStorage.setItem('user_token', this.Token);
                        
                        this.CSRF = info.XNAT_CSRF as string;
                        this.JSESSION = info.JSESSION as string;
                        this.AuthType = info.authenticationType as string;
                        
                        this.ShowOTP = true;
                        this.ShowTokenSubmit = false;
                        if (info.authenticationType?.toLowerCase() !== 'authenticator') {
                            // show button to resend
                            this.AuthenticationService.RequestOTP(this.Token)
                                .then((result: any) => {
                                    // In case the session has not expired go to Downloads page automatically
                                    if (result.approval_id) {
                                        this.$router.push({ name: 'Downloads', query: { token: this.Token, jesession: this.JSESSION } });
                                    }
                                });
                        }
                    });
            });
        event.preventDefault();
    }

    public ConfirmLogin(event: Event): void {
        this.AuthenticationService.Login(this.CSRF, this.JSESSION, this.OTP, this.AuthType, this.Token)
            .then((confirm: AuthenticationConfirmation) => {
                if (!confirm.success) {
                    // Add some messaging
                    this.ShowOTPError = true;
                    return;
                }
                this.ShowOTPError = false;
                this.$router.push({ name: 'Downloads', query: { token: this.Token, jesession: this.JSESSION } });
            });
        event.preventDefault();
    }
}
